import { CORE_END_POINT } from '../config/settings';
import AppConfig from '../config/AppConfig';
import AppStorage from '../config/AppStorage';
import { createFetchRequest, status, json, fetchRequest } from './apiHelper';
import { deleteDevice, getDevices, editDevice, addDevice, addDeviceToGroups, deleteDeviceFromGroups } from './deviceApi';

export { deleteDevice, getDevices, editDevice, addDevice, addDeviceToGroups, deleteDeviceFromGroups };

export const getMe = (token) => {
    let url = `${CORE_END_POINT}/me`;
    let request = fetchRequest(url, token, 'GET');

    return fetch(request)
        .then(status)
        .then(json)
        .catch(function (error) {
            console.log('Request failed for getMe: ', error);
        });
};

export const getOrganizations = (token) => {
    let url = `${CORE_END_POINT}/organizations`;

    let request = fetchRequest(url, token, 'GET');

    return fetch(request)
        .then(status)
        .then(json)
        .catch(function (error) {
            console.log('Request failed for getOrganizations:: ', error);
        });
};

export const getGroupsForOrganizations = (token, orgId) => {
    let url = `${CORE_END_POINT}/organizations/${orgId}/groups`;

    let request = fetchRequest(url, token, 'GET');

    return fetch(request)
        .then(status)
        .then(json)
        .catch(function (error) {
            console.log('Request failed for getGroupsForOrganizations: ', error);
        });
};

function OrgsFormatChangeAdapter(org) {
    return {
        name: org.organization.name,
        description: org.organization.description,
        orgId: org.organization.id,
        lat: org.location.latitude,
        long: org.location.longitude,
        alt: org.location.altitude
    };
}


function GroupFormatAdapter(newGroupObject, isSite, orgId, regionId, siteId) {

    let oldFormat = {
        orgId: orgId,
        regionId: regionId,
        name: newGroupObject.group.name,
        description: newGroupObject.group.description
    };

    if (isSite) {
        oldFormat.siteId = siteId;
    }
    if (newGroupObject.metadata) {
        Object.keys(newGroupObject.metadata).forEach(key => {
            if (key === 'location') {
                const location = JSON.parse(newGroupObject.metadata.location);
                oldFormat.lat = location.latitude;
                oldFormat.long = location.longitude;
                oldFormat.alt = location.altitude;
            } else {
                oldFormat[key] = newGroupObject.metadata[key];
            }
        });
    }

    return oldFormat;
}

export const getRegionsAndSitesForOrganization = (token, orgId) => {
    return getGroupsForOrganizations(token, orgId).then((r, reject) => {
        return filterRegionsAndSites(r, orgId, reject);
    });
};

const filterRegionsAndSites = (groupsResponse, orgId, reject) => {
    try {
        if (reject) return Promise.reject(reject);
        if (!groupsResponse || groupsResponse.groups.length === 0) return Promise.reject('no groups');

        let regions = [];
        let regionSites = {};

        groupsResponse.groups.forEach(group => {
            if (group.group.id !== orgId && group.group.parent === orgId) {
                regions.push(GroupFormatAdapter(group, false, orgId, group.group.id));
            }
        });


        regions.forEach(region => {
            let tempSites = [];
            groupsResponse.groups.map(group => {
                if (group.group.id !== region.regionId && group.group.parent === region.regionId) {
                    tempSites.push(GroupFormatAdapter(group, true, orgId, group.group.parent, group.group.id));
                }
                return null;
            });
            regionSites[region.regionId] = tempSites;
        });

        regions.sort((a, b) => {
            return a.name < b.name ? -1 : (a.name > b.name ? 1 : 0);
        });

        Object.keys(regionSites).forEach((ref) => {
            regionSites[ref].sort((a, b) => {
                return a.name < b.name ? -1 : (a.name > b.name ? 1 : 0);
            });
        });

        return Promise.resolve({
            regions,
            sites: regionSites
        });

    } catch (error) {
        return Promise.reject();
    }
};


export const getUserSites = (token) => {
    let usersites = { organizations: [], regions: [], sites: [] };
    const sessionSite = AppStorage.getSelectedSite();

    return getOrganizations(token).then((r) => {
        if (!r || !r.organizations || r.organizations.length === 0) {
            return Promise.reject('no orgs');
        }
        usersites.organizations = r.organizations;

        let selectedOrg = r.organizations.find(organization => {
            return sessionSite.org === organization.organization.id;
        }) || r.organizations[0];

        usersites.selectedOrg = selectedOrg;

        return Promise.resolve(selectedOrg);
    }).then((selectedOrg, reject) => {
        if (reject) return Promise.reject(reject);
        return getRegionsAndSitesForOrganization(token, selectedOrg.organization.id);
    }).then((regionsAndSites, reject) => {

        if (reject) return Promise.reject(reject);

        let orgs = [];
        // convert
        usersites.organizations.forEach((org) => {
            orgs.push(OrgsFormatChangeAdapter(org));
        });

        return Promise.resolve({
            orgs,
            regions: regionsAndSites.regions,
            sites: regionsAndSites.sites
        });
    });
};

export const getOrgs = (token) => {
    return getOrganizations(token).then((r) => {
        if (!r || !r.organizations || r.organizations.length === 0) {
            return Promise.reject('no orgs');
        }

        let orgs = [];
        // convert
        r.organizations.forEach((org) => {
            orgs.push(OrgsFormatChangeAdapter(org));
        });

        return Promise.resolve(orgs);
    });
};

export const getRegions = (token, orgId) => {
    return getRegionsAndSitesForOrganization(token, orgId).then((r, reject) => {
        if (reject) return Promise.resolve();
        return Promise.resolve(r.regions);
    });
};

export const getSites = (token, orgId, regionId) => {
    return getRegionsAndSitesForOrganization(token, orgId).then((r, reject) => {
        if (reject) return Promise.resolve();
        return Promise.resolve(r.sites[regionId]);
    });
};


export const getOrgsFromCache = () => {
    return Promise.resolve(AppConfig.getUserSites().orgs);
};

export const getRegionsFromCache = () => {
    return Promise.resolve(AppConfig.getUserSites().regions);
};

export const getSitesFromCache = (regionId) => {
    return Promise.resolve(AppConfig.getUserSites().sites[regionId]);
};

// not used?
export const deleteSiteReports = (USER, reportRef) => {
    if (!reportRef) return Promise.resolve(true);

    return new Promise((resolve) => {
        const db = AppConfig.getDbCon();
        var firbaseReport = db.ref(reportRef);
        firbaseReport.remove().then(function () {
            resolve(true);
        }).catch(function () {
            resolve(false);
        });

    });
};

/**
 *
 * Get Group
 * @param {string} token jwt Token
 * @param {string} orgId organization id
 * @param {string} parentId parentId id
 * @param {string} childId childId id
 * @return {promise} Promise data
 */
export const getGroups = (token, orgId, parentId, childId) => {
    let url = `/orgs/${orgId}/parents/${parentId}/childGroups/${childId}`;

    console.log(url);

    let request = createFetchRequest(url, token, 'GET');

    return fetch(request)
        .then(status)
        .then(json)
        .catch(function (error) {
            console.log('Request failed for getChildGroups:: ', error);
        });
};

export const getUserExternalLinkReports = (USER) => {
    const path = AppConfig.getUserExternalLinkPath(USER);
    return new Promise((resolve) => {
        AppConfig.getDbCon().ref(path).once('value', function (snapshot) {
            var reportsObj = snapshot.val();
            if (!reportsObj) return resolve([]);
            let list = [];
            for (let ref in reportsObj) {
                let report = reportsObj[ref];
                list.push({ report, ref: report.ref });
            }
            resolve(list);
        });
    });
};

export const saveUserExternalLink = (firebase, USER, report, ref) => {
    const path = AppConfig.getUserExternalLinkPath(USER);
    const db = AppConfig.getDbCon();
    return new Promise((resolve) => {
        const dashboardKey = db.ref().child(path).push().key;
        const fullPath = ref ? ref : (path + '/' + dashboardKey);

        // full path
        report.ref = fullPath;

        db.ref(fullPath).set(report, function (error) {
            if (error) {
                resolve(false);
            } else {
                resolve(true);
            }
        });
    });
};

/**
 *
 * Get Bay Image
 * @param {string} baseURLBayMontioring baseURL for bay monitoring
 * @param {string} token jwt Token
 * @param {string} orgId organization id
 * @param {string} timestamp The latest image before the provided timestamp is returned (imageTimestamp <= timestamp)
 * @param {string} serialNumber serial number of the bay
 * @return {string} base64String the bay image in base64 format
 */
export const getBayImage = async function (baseURLBayMontioring, token, orgId, timestamp, serialNumber) {
    const url = `${baseURLBayMontioring}/organizations/${orgId}/image?timestamp=${timestamp}&serialNumber=${serialNumber}`;
    const request = fetchRequest(url, token, 'GET');
    try {
        const response = await fetch(request);
        return await response.json();
    } catch (error) {
        console.log('Request failed getBayImage: ', error);
    }
};