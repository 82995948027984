import React from 'react';
import { RaisedButton } from 'material-ui';
import TextField from 'material-ui/TextField';

const DEVICE_TYPE_LPR_BAY = 'lpr-bay';
export class AreaOfInterest extends React.Component {
    constructor(props) {
        super(props);
        // this.state = {
        //     selectedSngDeviceItem: {},
        // };
    }
    render() {
        return(
            <div style={{display: 'flex', alignItems: 'flex-end', gap: '10px' }}>
                <TextField
                    floatingLabelText="Area of Interest Co-ordinates"
                    disabled={this.props.selectedSngDeviceItem['type'] !== DEVICE_TYPE_LPR_BAY}
                    value={
                        this.props.selectedSngDeviceItem &&
                        this.props.selectedSngDeviceItem['cameraConfig'] &&
                        this.props.selectedSngDeviceItem['cameraConfig'].imageBayCoords
                            ? this.props.selectedSngDeviceItem['cameraConfig'].imageBayCoords
                            : [0, 0, 1, 1]
                    }
                    onChange={(e) => {
                        this.props.changeSelectedSngDeviceItem(
                            { target: { value: { imageBayCoords: e.target.value } } },
                            'cameraConfig'
                        );
                    }}
                    style={{ flex: 1 }}
                />
                {/* Button to open the bay image preview modal */}
                <RaisedButton
                    onClick={this.props.openModal}
                    disabled={this.props.selectedSngDeviceItem['type'] !== DEVICE_TYPE_LPR_BAY}
                    style={{
                        padding: '3px',
                        height: '20px',
                        width: '20px',
                        marginBottom: '10px',
                        fontSize: '10px',
                        textAlign: 'center',
                        alignItems: 'center',
                        borderColor: 'gray',
                        justifyContent: 'center',

                    }}
                >
                    Area of Interest
                </RaisedButton>
            </div>
        );
    }
}

export default AreaOfInterest;