import React from 'react';
import { RaisedButton } from 'material-ui';
import { Dialog } from 'material-ui';

// input timestamp and an image and retrieve the bounding box of the vehicle
export class AreaOfInterestDialog extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            originalImageWidth: 0, // State to store the original bay image width
            originalImageHeight: 0, // State to store the original bay image height
            firstClick: null,
            secondClick: null, // Store the second click position
            clickCount: 0,
            marks: [], // Array to store marks on the canvas
            scaleX: 1, // Scaling factor for the bay image
            scaleY: 1, // Scaling factor for the bay image
        };
        this.canvasRef = React.createRef();
        this.imageRef = React.createRef();
    }

    handleImageClick = (event) => {
        if(this.state.clickCount >= 2){ // reset click count after 2 clicks, so all the marks will be cleared
            this.setState({clickCount:0});
            this.setState({firstClick: null, secondClick: null});
            this.setState({marks: []});
            return;
        }
        const { offsetX, offsetY } = event.nativeEvent; // Get the click position relative to the image
        if (!this.state.firstClick) {
            this.setState({ firstClick: { x: offsetX, y: offsetY } });
        } else {
            this.setState({ secondClick: { x: offsetX, y: offsetY } });
        }
        const newMark = { x: offsetX, y: offsetY }; // Store click coordinates
        this.setState((prevState) => ({
            marks: [...prevState.marks, newMark],
        }));
        const imageElement = event.target;
        const displayedWidth = imageElement.width;
        const displayedHeight = imageElement.height;

        // Calculate scaling factor
        const scaleX = this.state.originalImageWidth / displayedWidth;
        const scaleY = this.state.originalImageHeight / displayedHeight;

        // set the scaling factors in the state
        this.setState({ scaleX: scaleX });
        this.setState({ scaleY: scaleY });
        this.setState({
            clickPosition: { x: offsetX, y: offsetY },
        });
        this.setState({clickCount: this.state.clickCount + 1});
    };

    handleImageLoad = (event) => {
        const imgElement = event.target;
        this.setState({ originalImageWidth: imgElement.width, originalImageHeight: imgElement.height });
        const canvas = this.canvasRef.current;
        /* the canvas overlay on top of the image and the canvas height and width should be same as the image
            height and width, so that the bounding box can be drawn on top of the image
        */
        if (canvas) {
            canvas.width = imgElement.clientWidth; // Match displayed image width
            canvas.height = imgElement.clientHeight; // Match displayed image height
            this.retrieveBoundingBox();
        }
    };

    drawMarks = () => {
        const imgElement = this.imageRef.current;
        const canvas = this.canvasRef.current;
        if(!canvas){
            console.log('Canvas not found');
            return;
        }
        const ctx = canvas.getContext('2d');
        canvas.width = imgElement.width;
        canvas.height = imgElement.height;
        const { marks } = this.state;

        // Draw each mark as a red circle
        marks.forEach(mark => {
            ctx.beginPath();
            ctx.arc(mark.x, mark.y, 3, 0, Math.PI * 2);
            ctx.fillStyle = 'red';
            ctx.fill();
        });
    };

    // Draw the bounding box
    drawBoundingBox = () => {
        const canvas = this.canvasRef.current;
        const { firstClick, secondClick } = this.state;

        if (!canvas || !firstClick || !secondClick) return;
        const ctx = canvas.getContext('2d');

        // Calculate bounding box dimensions using the firstClick and secondClick coordinates
        let x = Math.min(firstClick.x, secondClick.x);
        let y = Math.min(firstClick.y, secondClick.y);
        let width = Math.abs(secondClick.x - firstClick.x);
        let height = Math.abs(secondClick.y - firstClick.y);

        // Draw bounding box
        ctx.beginPath();
        ctx.rect(x, y, width, height);
        ctx.lineWidth = 2;
        ctx.strokeStyle = 'blue';
        ctx.stroke();
        ctx.closePath();

        /*
            We want to save the normalized bounding box coordinates to the clipboard, so that the user can paste it
            to the area of interest text field. Need ScaleX and ScaleY to normalize the coordinates to 0-1 range
        */
        const {scaleX, scaleY} = this.state;
        x = Math.round(x * scaleX);
        y = Math.round(y * scaleY);
        width = Math.round(width * scaleX);
        height = Math.round(height * scaleY);

        // Scale down coordinates
        const xmin = Math.round(Math.min(firstClick.x * scaleX, secondClick.x * scaleX));
        const ymin = Math.round(Math.min(firstClick.y * scaleY, secondClick.y * scaleY));
        const xmax = Math.round(Math.max(firstClick.x * scaleX, secondClick.x * scaleX));
        const ymax = Math.round(Math.max(firstClick.y * scaleY, secondClick.y * scaleY));

        width = Math.round(Math.abs(xmax - xmin));
        height = Math.round(Math.abs(ymax- ymin));

        // Normalized x, y, width, height to 0-1 range and copy to clipboard
        const {originalImageWidth, originalImageHeight} = this.state;
        x = (x / originalImageWidth).toFixed(2);
        y = (y / originalImageHeight).toFixed(2);
        width = (width / originalImageWidth).toFixed(2);
        height = (height / originalImageHeight).toFixed(2);

        navigator.clipboard.writeText('['+x+','+y+','+width+','+height+']')
            .then(() => {
            // show a tooltip that the text was copied
                this.props.showSnakBar('Area of interest '+ '['+x+','+y+','+width+','+height+']'+' copied to clipboard.'
                );
            })
            .catch((err) => {
                console.error('Error copying text: ', err);
            });
    };

    componentDidUpdate(prevProps, prevState) {
        if (this.state.marks !== prevState.marks) {
            this.drawMarks();
        }
        // this.drawBoundingBox();
        if (this.state.firstClick !== prevState.firstClick ||
            this.state.secondClick !== prevState.secondClick) {
            this.drawBoundingBox();
        }
    }

    /* Method to retrieve the bounding box from the Area of Interest text field and draw it on the canvas */
    retrieveBoundingBox = () => {
        const { selectedSngDeviceItem } = this.props;
        if (!selectedSngDeviceItem) return;
        const { cameraConfig } = selectedSngDeviceItem;
        if (!cameraConfig) return;
        const { imageBayCoords } = cameraConfig;
        if (!imageBayCoords) return;
        // Get the canvas and draw the bounding box on it
        const canvas = this.canvasRef.current;
        if (!canvas) return;
        const ctx = canvas.getContext('2d');

        /* The imageBayCoordsArray origins from the Area of Interest text field, they are saved in the
           this.state.selectedSngDeviceItem.cameraConfig.imageBayCoords in the
           format [x, y, width, height] and are normalized to the 0-1 range, transform them to pixel and draw the
           bounding box on the canvas
        */
        const imageBayCoordsArray = JSON.parse(imageBayCoords);
        const x1 = Math.round(imageBayCoordsArray[0] * canvas.width);
        const y1 = Math.round(imageBayCoordsArray[1] * canvas.height);
        const width = Math.round(imageBayCoordsArray[2] * canvas.width);
        const height = Math.round(imageBayCoordsArray[3] * canvas.height);
        ctx.beginPath();
        ctx.rect(x1, y1, width, height);
        ctx.lineWidth = 2;
        ctx.strokeStyle = 'blue'; // Bounding box color
        ctx.stroke();
        ctx.closePath();
    };

    render() {
        return(
            <div>
                <Dialog
                    id="imageModal"
                    open={this.props.modalOpen}
                    onRequestClose={this.closeModal}
                    style = {{top: '-25%'}}
                >
                    <div style={{ paddingTop: '5px' }}>
                        {this.props.bayImage === null && (
                            <div>
                                Loading image...
                            </div>
                        )}
                        {this.props.bayImage =='' && (
                            <div>
                                No image available on google cloud storage at the moment, you may need to wait for the image to be uploaded, try again later.
                            </div>
                        )}
                        <div>
                            {this.props.bayImage && (
                                <img
                                    id="vehicleImage"
                                    ref={this.imageRef}
                                    src={this.props.bayImage}
                                    style={{ width: '100%', height: 'auto' ,position: 'absolute', top: 105, left: 0}}
                                    onClick={this.handleImageClick}
                                    onLoad = {this.handleImageLoad}
                                />
                            )}
                            {/* Canvas for drawing marks */}
                            {this.props.bayImage && (
                                <canvas
                                    ref={this.canvasRef}
                                    width="100%"
                                    height="100%"
                                    style={{
                                        position: 'absolute',
                                        top: 105,
                                        left: 0,
                                        pointerEvents: 'none' // Disable canvas interactions so clicks pass to the image
                                    }}
                                />
                            )}
                        </div>
                    </div>
                    <div style={{  display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '10px' }}>
                        {/* <div> Image captured time: {this.props.bayImageTimestamp}</div> */}
                        {this.props.bayImageTimestamp && (
                            <div>Image captured time: {this.props.bayImageTimestamp}</div>
                        )}
                        <div style={{  textAlign: 'right' }}>
                            <RaisedButton onClick={this.props.closeModal}>Close</RaisedButton>
                        </div>
                    </div>

                </Dialog>
            </div>
        );
    }
}

export default AreaOfInterestDialog;